<template>
  <b-card no-body class="p-1">
    <b-overlay
        :show="loading"
        rounded="sm"
    >

      <search-and-filter
          :is-active.sync="isActive"
          :options="columns"
          @filter="getData(1, perPage)"
          @reset="getData(1, perPage)"
      />

      <!--            diposit-modal-->
      <b-modal
          ref="dip-modal"
          cancel-variant="outline-secondary"
          ok-title="واریز شد"
          cancel-title="لغو"
          centered
          :title="'واریز '+cryptoName"
          @ok="submit"
          v-model="dipModal"
      >
        <h5>
          جهت واریز به کیف پول {{ cryptoName }} سایت خود از QRcode آدرس زیر استفاده نمایید
        </h5>
        <p v-tooltip="'کپی آدرس'" class="text-center py-1 cursor-pointer" @click="copy">
          {{ depositAddress }}
        </p>
        <p v-tooltip="'کپی آدرس'" class="text-center py-1 cursor-pointer" @click="copy">
          tag : {{ tag }}
        </p>
        <b-card
            :img-src="'data:image/gif;base64,'+QRcode"
            img-alt="Card image cap"
            img-top
            no-body
            style="max-width:300px"
            class="mx-auto my-1"
        >
        </b-card>

      </b-modal>
      <!--            withdraw-modal-->
      <b-modal
          ref="with-modal"
          cancel-variant="outline-secondary"
          ok-title="ارسال "
          cancel-title="لغو"
          centered
          :title="'برداشت '+cryptoName"
          @ok.prevent="submitWithdraw"
          @cancel.prevent="() => {if(!loading) this.withModal=false}"
          v-model="withModal"
      >
        <b-overlay
            :show="loading"
            rounded="sm"
        >
          <b-form>
            <b-form-group label="مقدار درخواستی">
              <b-form-input
                  id="value"
                  type="text"
                  :placeholder="'مقدار' + cryptoName"
                  v-model="withdrawRequest.value"
              />
            </b-form-group>
            <b-form-group label="آدرس کیف پول مقصد">
              <b-form-input
                  id="address"
                  type="text"
                  placeholder="0Xd54d"
                  v-model="withdrawRequest.outputAddress"
              />
            </b-form-group>
            <b-form-group label="رمز کیف پول" v-if="['STELLAR','RIPPLE'].includes(cryptoUnit)">

              <b-input-group class="mb-1">
                <b-form-input :state="withdrawRequest.password.length>9" dir="ltr" class="text-left"
                              v-model="withdrawRequest.password" trim placeholder="رمز کیف پول"
                              :type="passwordType"/>
                <b-input-group-append is-text>
                  <feather-icon
                      :icon="passwordType==='password'?'EyeIcon':'EyeOffIcon'"
                      class="cursor-pointer"
                      @click="passwordType = passwordType==='password'? 'text':'password'"
                  />
                </b-input-group-append>
              </b-input-group>

            </b-form-group>
            <b-form-group label="کلید امنیتی 12 کلمه ای" v-else>

              <b-input-group class="mb-1">
                <b-form-textarea
                    :state="mnemonicRegex(withdrawRequest.mnemonic)"
                    dir="ltr" class="text-left"
                    v-model="withdrawRequest.mnemonic" trim placeholder="12 key phrase"
                />
              </b-input-group>

            </b-form-group>
          </b-form>
        </b-overlay>
      </b-modal>

      <div class="demo-spacing-0 m-1 d-flex justify-content-between align-items-center"
           dir="rtl"
      >
        <h3 v-if="$route.name === 'show-accounts'">
          لیست زیر مجموعه ها
        </h3>
        <b-button v-else v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">
          <feather-icon icon="FilterIcon"/>
          فیلتر
        </b-button>
      </div>

      <div class="relative-over-x">
        <b-table
            ref="refUserListTable"
            :items="items"
            :small="true"
            responsive
            :fields="columns"
            primary-key="id"
            show-empty
            empty-text="اطلاعاتی یافت نشد"
            @sort-changed="sort($event)"
            no-local-sort
            class="text-nowrap"
            style="min-height : 235px"
        >
          <template #cell(relatedCoin)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                    size="32"
                    :src="require(`@/assets/images/Coins/${data.item.relatedCoin}.png`)"
                    :variant="`light-info`"
                    :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
                />
              </template>
              <b-link
                  :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
                  class="font-weight-bold d-block text-nowrap"
              >
                {{ $coinLabel[data.item.relatedCoin] }}
              </b-link>
              <small class="text-muted">{{ data.item.relatedCoin }} ({{ data.item.site }})</small>
            </b-media>
          </template>

          <template #cell(walletName)="data">
                        <span>
                            {{ data.item.walletName }}
                        </span>
          </template>

          <template #cell(address)="data">
                        <span>
                            {{ data.item.tag || data.item.memo || data.item.address }}
                        </span>
          </template>

          <template #cell(ownersUsername)="data">
            <b-link :to="{name:'Accounts',query:{email: data.item.ownersUsername}}">
              {{ data.item.exchangeOwner ? 'صرافی' : data.item.ownersUsername || 'اختصاص داده نشده' }}
            </b-link>
          </template>

          <template #cell(unitPrice)="data">
                        <span dir="ltr">
                            {{ $toLocal(data.item.unitPrice, $decimal['TOMAN']) }}
                        </span>
          </template>

          <template #cell(balance)="data">
                        <span dir="ltr">
                            {{ $toLocal(data.item.balance, $decimal[data.item.relatedCoin]) }}
                        </span>
          </template>

          <template #cell(credit)="data">
                        <span dir="ltr">
                            {{ $toLocal(data.item.credit, $decimal[data.item.relatedCoin]) }}
                        </span>
          </template>

          <template #cell(blockedCredit)="data">
                        <span dir="ltr">
                            {{ $toLocal(data.item.blockedCredit, $decimal[data.item.relatedCoin]) }}
                        </span>
          </template>

          <template #cell(confirmedCredit)="data">
                        <span dir="ltr">
                            {{ $toLocal(data.item.confirmedCredit, $decimal[data.item.relatedCoin]) }}
                        </span>
          </template>

          <template #cell(wholePriceOfBalance)="data">
                        <span dir="ltr">
                            {{
                            data.item.wholePriceOfBalance === null ? 'سفارشی ثبت نشده' : $toLocal(data.item.wholePriceOfBalance, $decimal['TOMAN'])
                          }}
                        </span>
          </template>


          <template #cell(action)="data">
            <b-dropdown
                id="dropdown-offset"
                variant="link"
                no-caret
                offset="80px"
                :right="true"
            >
              <template #button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                />
              </template>
              <b-dropdown-item v-if="$havePermission('CREATE_WALLET') && data.item.relatedCoin!=='TOMAN'"
                               @click="deposit($coinLabel[data.item.relatedCoin],data.item.relatedCoin,data.item.walletName)">
                <feather-icon icon="DownloadIcon"/>
                <span class="align-middle ml-50">واریز به کیف پول</span>
              </b-dropdown-item>

              <b-dropdown-item v-if="$havePermission('CREATE_WALLET') && data.item.relatedCoin!=='TOMAN'"
                               @click="withdraw($coinLabel[data.item.relatedCoin],data.item.relatedCoin,data.item.walletName)">
                <feather-icon icon="UploadIcon"/>
                <span class="align-middle ml-50">برداشت از کیف پول</span>
              </b-dropdown-item>

              <!--<b-dropdown-item :to="{name:'wallet-history',query:{crypto : data.item.cryptoUnit}}">
                     <feather-icon icon="ClockIcon"/>
                     <span class="align-middle ml-50">سوابق تراکنش</span>
                  </b-dropdown-item>-->
            </b-dropdown>
          </template>
        </b-table>
      </div>

      <!-- pagination -->
      <div
          class="demo-spacing-0 d-flex justify-content-between m-1"
          dir="rtl"
      >
        <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            align="left"
            @input="getData(currentPage,perPage)"
        >
          <template #prev-text>
            <feather-icon
                icon="ChevronLeftIcon"
                size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
                icon="ChevronRightIcon"
                size="18"
            />
          </template>
        </b-pagination>
        <div>
          <label for="perpage">تعداد در صفحه</label>
          <v-select
              id="perpage"
              v-model="perPage"
              dir="rtl"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              style="min-width: 85px"
              @input="getData(currentPage,perPage)"
          />
        </div>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BPagination,
  BCard,
  BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  // BBadge,
  BMedia,
  BAvatar,
  BForm,
  BFormGroup,
  BFormInput,
  BLink,
  BOverlay,
} from 'bootstrap-vue'
import SearchAndFilter from "@/layouts/components/SearchAndFilter";
// import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select';

export default {
  name: 'Addresses',
  components: {
    SearchAndFilter,
    BCard,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    // BBadge,
    BMedia,
    BAvatar,
    BForm,
    BFormGroup,
    BFormInput,
    BPagination,
    // Cleave,
    BLink,
    vSelect,
    BOverlay
  },
  data: () => ({
    url:'',
    test: 0,
    pageLength: 3,
    dir: false,
    searchTerm: '',
    currentPage: 1,
    perPage: 10,
    rows: 200,
    sortBy: '',
    cryptoName: '',
    cryptoUnit: '',
    walletName: '',
    QRcode: '',
    isSortDirDesc: '',
    withdrawRequest: {
      walletName: '',
      password: '',
      value: '',
      outputAddress: ''
    },
    depositAddress: '',
    amount: '',
    userData: [],
    isActive: false,
    withModal: false,
    dipModal: false,
    feeModal: false,
    loading: false,
    perPageOptions: [5, 10, 20, 50, 100],
    items: [],
    columns: [
      {
        label: 'رمز ارز',
        key: 'relatedCoin',
        sortable: false,
        searchType: 'select',
        selectOptions: [
          // {label: 'تومان', value: 'TOMAN'},
          {label: 'بیتکوین', value: 'BITCOIN'},
          {label: 'اتریوم', value: 'ETHEREUM'},
          {label: 'اتریوم کلاسیک', value: 'CLASSIC_ETHEREUM'},
          {label: 'تتر', value: 'TETHER'},
          {label: 'بیتکوین کش', value: 'BITCOIN_CASH'},
          {label: 'لایت کوین', value: 'LITE_COIN'},
          {label: 'دوج کوین', value: 'DOGE_COIN'},
          {label: 'دش کوین', value: 'DASH'},
        ]
      },
      {
        label: 'نام کیف پول',
        key: 'walletName',
        sortable: false,
        searchType: 'text'
      },
      {
        label: 'متعلق به',
        key: 'ownersUsername',
        sortable: false,
        searchType: 'text'
      },
      {
        label: 'موجودی',
        key: 'balance',
        sortable: true,
        searchType: 'number'
      },
      {
        label: 'موجودی (تومان)',
        key: 'wholePriceOfBalance',
        sortable: false,
        // searchType: 'number'
      },
      {
        label: 'اعتبار آدرس',
        key: 'credit',
        sortable: false,
        // searchType: 'number'
      },
      {
        label: 'اعتبار بلوکه شده',
        key: 'blockedCredit',
        sortable: false,
        // searchType: 'number'
      },
      {
        label: 'اعتبار قابل برداشت',
        key: 'confirmedCredit',
        sortable: false,
        // searchType: 'number'
      },
      {
        label: 'آدرس کیف پول',
        key: 'address',
        sortable: false,
        searchType: 'text',
      },
      /*{
        label: 'عملیات',
        key: 'action'
      },*/
    ],
  }),
  watch: {
    '$route.name'() {
      this.getData()
    }
  },
  computed: {
    mnemonicRegex() {
      return e => /^\s*\S+(?:\s+\S+){11,}\s*$/.test(e)
    }
  },
  methods: {
    submit() {
      this.dipModal = false
      this.withModal = false
    },
    copy() {
      navigator.clipboard.writeText(this.depositAddress)
      alert('متن کپی شد')
    },
    sort(e) {
      console.log(e)

      let sort = this.$toSnakeCase(e.sortBy)
      // let sort = e.sortBy
      let sorting = e.sortDesc ? 'DESC' : 'ASC'

      this.$router.push({
        query: {
          ...this.$route.query,
          orderBy: sort,
          sorting: sorting
        }
      })

      this.getData(1, this.perPage)

    },
    async getData(page, perPage = this.perPage) {
      if(this.$route.query.s){
        return this.getData1(page, perPage)
      }
      this.loading = true
      const address = this.$route.name === 'x-addresses' ? '/wallets/account-addresses' : '/wallets/hd/addresses'

      let queryParams = {
        size: Math.ceil(perPage / this.$urls.length),
        page: page,
        ...this.$route.query,
      }
      let items = []
      let rows = 0
      for (const url of this.$urls) {
        const res = await this.$axios(url[1] + address, {params: queryParams})
        items.push(...res.data.content.map(e => {
          return {
            ...e,
            site: url[0]
          }
        }))
        rows += res.data.totalElements
      }
      // items = items.sort((a, b) => (b.ownersUsername||'').localeCompare(a.ownersUsername||''))
      this.items = items
      this.rows = rows
      this.loading = false
    },
    async getData1(page, perPage = this.perPage) {
      this.loading = true
      const address = this.$route.name === 'x-addresses' ? '/wallets/account-addresses' : '/wallets/hd/addresses'

      let queryParams = {
        size: perPage,
        page: page,
        ...this.$route.query,
      }
      let items = []
      let rows = 0
      const res = await this.$axios(this.url + address, {params: queryParams})
      items.push(...res.data.content.map(e => {
        return {
          ...e,
        }
      }))
      rows += res.data.totalElements
      // items = items.sort((a, b) => (b.ownersUsername||'').localeCompare(a.ownersUsername||''))
      this.items = items
      this.rows = rows
      this.loading = false
    },
    async deposit(l, u, n) {
      const res = await this.$axios.get('/wallets/to-deposit-address', {
        params: {
          walletName: n,
          relatedCoin: u,
        }
      })
      this.QRcode = res.data.baseDTO.qrCode
      this.depositAddress = res.data.baseDTO.address
      this.tag = res.data.baseDTO.tag || res.data.baseDTO.memo
      this.cryptoName = l
      this.cryptoUnit = u
      this.$refs['dip-modal'].show();
    },
    withdraw(l, u, n) {
      this.withdrawRequest.walletName = n
      this.cryptoName = l
      this.cryptoUnit = u
      this.$refs['with-modal'].show();
    },
    async submitWithdraw() {
      if (this.withdrawRequest.value && (['ETHEREUM', 'CLASSIC_ETHEREUM', 'TETHER'].includes(this.cryptoUnit) ? true : this.withdrawRequest.password) && this.withdrawRequest.outputAddress && !this.loading) {
        this.loading = true
        const address = ['ETHEREUM', 'CLASSIC_ETHEREUM', 'TETHER'].includes(this.cryptoUnit) ? '/wallets/transaction' : '/wallets/hd/transaction'
        await this.$axios.post(address, this.withdrawRequest)
            .then(() => {
              this.loading = false
              this.$swal({
                icon: 'success',
                title: 'مقدار درخواستی انتقال یافت',
                confirmButtonText: 'تایید',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              }).then(() => {
                this.withModal = false
              })
            })
            .catch(() => {
              this.loading = false
            })
      }
    },
  },
  mounted() {
    if(this.$route.query.s){
      this.url = this.$urls.find(e => e[0] === this.$route.query.s)[1]
      this.getData1()
    }else {
      this.getData()
    }
  },
}
</script>
<style lang="scss">
[v-cloak] {
  opacity: 0;
}

[dir="rtl"] .test-badge {
  font-size: 10px;
  top: -6px;
  left: -5px !important;
  min-width: 16px;
  min-height: 16px;
}
</style>
